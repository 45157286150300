/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPitchbook = /* GraphQL */ `
  query GetPitchbook($id: ID!) {
    getPitchbook(id: $id) {
      id
      type
      title
      description
      imageURL
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPitchbooks = /* GraphQL */ `
  query ListPitchbooks(
    $filter: ModelPitchbookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPitchbooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        imageURL
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPitchbooks = /* GraphQL */ `
  query SyncPitchbooks(
    $filter: ModelPitchbookFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPitchbooks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        title
        description
        imageURL
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
