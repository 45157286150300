
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import phpTrsRal from '../assets/images/category.PNG';
import problem2 from '../assets/images/problem-1.png';
import solution from '../assets/images/solution.png';
import TechStack from '../assets/images/TechStack.png';
import ListingHeader from '../Common/ListingHeader';


import { API, graphqlOperation } from '@aws-amplify/api';
import { listPitchbooks } from '../graphql/queries';

class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {


        }
    }
    componentDidMount() {
         //this.getSalesSlides();
      
    }

    getSalesSlides =async()=>{
        const todoData = await API.graphql(graphqlOperation(listPitchbooks))
        const todos = todoData.data.listPitchbooks.items
        console.log(todos);
    }

    render() {
        return (
            <>
                <ListingHeader></ListingHeader>

                <section className="rental-steps-section">
                    <div className="container-fluid">
                        <ul className="rental-steps-wrap">

                        </ul>
                    </div>
                </section>
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box ">

                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h2 className="border-botom">What we do?</h2>
                                                    <p className="text_p mt-3">InsuranceGIG is a P&C Technology; App-Store/Marketplace designed to allow buyers and sellers to make data and API microservice connection quicly, easily and cost effectively.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                        <img src={phpTrsRal} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box ">

                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h2 className="border-botom">The Root Problem</h2>
                                                    <p className="text_p mt-3">There is no democratized Data Standard or API Microservice consistency in the P&C Insurance Industry value chain.</p>
                                                    <p className="text_p mt-3">The Technology across the supply chain coul have up to 11 seprate systems and integrations, with ZERO cross platform interationsor data communication.</p>

                                                    <p className="text_p mt-3">InsuranceGIG is open to all agencies, carriers & insurance technology providers to jointly contribute to and consume, a dynamic growing library of  connected API Microservices across the entire Commercial P&C value Chain</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                        <img src={problem2} alt="" className="img-fluid" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box ">

                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h2 className="border-botom">Solution..</h2>
                                                    <p className="text_p mt-3">InsuranceGIG is positioned as the first company with the capability to cluster and bundle indiviual microservice from disprate companies into one aggregated workflow the can be consimed by an single party super 'lite' integrations.</p>
                                                    <p className="text_p mt-3">With our unique combination of an open source model, relations, technology frameworks , volume of integrations, and our marketplace delivery mechanism we will have created a unique an highly defensible model.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                        <img src={solution} alt="" className="img-fluid" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box ">

                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h2 className="border-botom">How it works?</h2>
                                                    <p className="text_p mt-3">InsuranceGIG is positioned as the first company with the capability to cluster and bundle indiviual microservice from disprate companies into one aggregated workflow the can be consimed by an single party super 'lite' integrations.</p>
                                                    <p className="text_p mt-3">With our unique combination of an open source model, relations, technology frameworks , volume of integrations, and our marketplace delivery mechanism we will have created a unique an highly defensible model.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box ">

                                            <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                    <h2 className="border-botom">Technology Stack</h2>
                                                    <p className="text_p mt-3">InsuranceGIG is positioned as the first company with the capability to cluster and bundle indiviual microservice from disprate companies into one aggregated workflow the can be consimed by an single party super 'lite' integrations.</p>
                                                  

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                        <img src={TechStack} alt="" className="img-fluid" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid" id="top" name="top">
                        <div className="box-shadow-white-box-a ">
                        <h2 className="border-botom">Timeline</h2>    
                            <div className="">
                            <div className="timeline_IG">
                    <div className="container_IG left">
                        <div className="date">15 Dec</div>
                        <i className="icon fa fa-magic "></i>
                        <div className="content_IG">
                            <h2>Left</h2>
                            <p>
                                Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus erat sagittis non. Ut blandit semper
                                pretium.
                            </p>
                        </div>
                    </div>
                    <div className="container_IG right">
                        <div className="date">22 Oct</div>
                        <i className="icon fa fa-magic "></i>
                        <div className="content_IG">
                            <h2>right</h2>
                            <p>
                                Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus erat sagittis non. Ut blandit semper
                                pretium.
                            </p>
                        </div>
                    </div>
                    <div className="container_IG left">
                        <div className="date">15 Dec</div>
                        <i className="icon fa fa-magic "></i>
                        <div className="content_IG">
                            <h2>Left</h2>
                            <p>
                                Lorem ipsum dolor sit amet elit. Aliquam odio dolor, id luctus erat sagittis non. Ut blandit semper
                                pretium.
                            </p>
                        </div>
                    </div>
                </div>

                            </div>
                        </div>
                    </div>
                </section>
              
                <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
                <div className="box-shadow-white-box">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="p-3 contact-us-quick-box">
                                <h5>We look forward to serving you.</h5>
                                <p>To know further .....................contact us today.</p>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
            
            </>
        );
    }
}

export default hoistStatics(withTranslation()(Sales), Sales);